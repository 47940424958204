.itemsContainer{
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Playfair Display", serif;
}

.itemsContainer li{
  margin: 0;
  padding: 0 10px;
  display: inline-block;
  font-family: "Playfair Display", serif;
}

.itemsContainer a{
  text-decoration: none;
  font-family: "Playfair Display", serif;
}

@media (max-width: 1360px){

  .itemsContainer li{
    display: block;
    text-align: center;
  }
  
}