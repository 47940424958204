.orderDiv {
  background-color: #bd9476;
  align-self: center;
  margin: 2rem;
  width: 80%;
  height: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  position: relative;
  z-index: 4;
}
.orderHeader {
  color: black;
}

.content {
  display: grid;
  height: 100%;
  border-radius: 10px;
}
.clientForm {
  grid-column: 1;
  margin: 3rem;
  margin-top: 1rem;
  width: 400px;
}

.order {
  grid-column: 2;
  margin-top: 3rem;
  width: 90%;
  max-height: 40rem;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.bin:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.068);
  border-radius: 5px;
}
.bin {
  text-align: center;
  padding: 3px;
  font-size: large;
}

.orderBox {
  background-color: white;
  border-radius: 10px;
  height: 300px;
  width: 600px;
}
.orderButton {
  margin-left: 35%;
  margin-top: 2rem;
}

.totalPrice {
  align-self: center;
  margin-left: 3rem;
  margin-top: 0;
}

.emptyBasket {
  font-size: xx-large;
}


@keyframes driveCar {
  0% {
    left: 68%;
    top: 50%;
  }
  100% {
    left: 32%;
    top: 50%;
  }
}

.confirmationPanel {
    height: 25rem;
    width: 50rem;
    align-self: center;
    background-color: #ffffff;
    color: black;
    border-radius: 10px;
}

.confirmationText{
  padding-top: 2rem;
  text-align: center;
}

.confirmationText img{
  position: absolute;
  animation-name: driveCar;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-direction: alternate;
  height:100px;
  width:100px;
  top:40%;
  margin-top: 4rem;
}

.emailNotValid{
  border: 5px;
  border-color: red;
}


@media (max-width: 1380px) {
  .content{
      display: block;
      width: 100%;
  }

  .orderDiv{
    height: 100%;
    width: 100%;

    margin:0;
  }

  .orderBox{
    display: flex;
    margin: 0 auto;
    width: 100%;
    height: 25%;
  }

  .order h1{
    font-size: x-large;
  }

  .order{
    width: 100%;
    margin: 0;
    height: 90%;
  }

  .clientForm{
    width: 90%;
    display: block;
    margin: 0 auto;
  }
  .orderButton{
    display: flex;
    width: 100%;
    margin-top: 0.5rem;
    margin-left: 0;
    margin-bottom: 0;
    justify-content: center;
    height: auto;
  }
  .emptyBasket {
    text-align: center;
  }


  .confirmationPanel{
    width: 100%;
    height: 35rem;
  }
  .confirmationText img{
    margin-top: 6rem;
  }


  .totalPrice{
    display: none;
  }
}

