.aboutGrid {
  display: grid;
  column-gap: 0px;
  margin-top: 6rem;
}

.imageDiv {
  padding-top: 4rem;
  padding-left: 12rem;
  grid-column: 1;
  max-width: 50%;
}
.image {
  width: 100%;
  height: auto;
  border-radius: 5%;
}
.about {
  left: 50%;
  grid-column: 2;
  padding-right: 10rem;
}
.about h1 {
  color: rgb(202, 36, 152);
  font-size: 40px;
  padding-top: 2rem;
}
.par {
  color: rgb(123, 73, 25);
  font-weight: 450;
  font-size: xx-large;
}

.ig {
  float: center;
  padding-top: 5px;
}
.ig a {
  color: rgb(202, 36, 152);
}
.fb {
  float: left;
  padding-top: 5px;
  margin-right: 20px;
}
.fb a {
  color: rgb(202, 36, 152);
}

.leaf {
  height: auto;
  width: 40%;
  float: right;
}

@media (max-width: 980px) {
  .aboutGrid {
    display: block;
    margin: 0;
    padding:0;
  }

  .imageDiv {
    padding-left: 0;
    text-align: center;
    width: 100%;
    max-width: max-content;
    margin:0;
    display: block;
    align-items: center;
  }
  .image img {
    margin:0;
    align-self: center;
  }

  .fb {
    display: inline-block;

    float: none;
    padding: 0;
    margin:0;
  }
  .ig {
    display: inline-block;
    float: none;
    margin: 0;
    padding:0;
    margin-left: 3em;
  }

  .about {
    text-align: center;
    display: inline;
    align-items: center;
  }

  .par {
    font-size: x-large;
    text-align: center;
    width: 90%;  
    margin: 1em auto;
  }

  .about h1{
    text-align: center;

  }
}
