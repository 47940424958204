@keyframes slideImage {
  0% {
    left: -100%;
    top: 68%;
  }
  100% {
    left: 18%;
    top: 68%;
  }
}
.mainPicture {
  margin-top: 6rem;
  height: 90vh;
  background-color: rgb(242, 224, 185);
  width: 60%;
  display: inline-block;
}

.mainPicture img {
  border-radius: 0px 15px 15px 0px;
  width: 100%;
  height: auto;
}

.mainText {
  background-color: blanchedalmond;
  border-radius: 15px;
  display: inline-block;
  width: 35%;
  margin-left: 2rem;
  height: 25rem;
  margin-top: -50%;
  display: inline-block;
  position: absolute;
  margin-top: 7rem;
}
.mainText h1 {
  color: rgb(123, 73, 25);
  font-size: 25px;
  font-weight: 500;
  margin-left: 10px;
}

.tel {
  background-color: #e3038c;
  border-radius: 15px;
  width: 70%;
  text-align: center;
  height: 3rem;
  margin-left: auto;
  border-color: rgb(255, 255, 255);
  border-width: 2px;
  border-style: solid;

}
.tel h2 {
  margin-top: 5px;
  color: white;
  font-weight: 400;
  margin-left: 1rem;
  display: inline-block;
}
.telLink {
  text-decoration: none;
  color:white;
}
.social {
  display: inline-block;
  margin: 10px;
}
.social a {
  color: rgb(123, 73, 25);
}

.onPic {
  background-color: rgba(242, 224, 185, 0.895);
  border-radius: 15px 0 0 15px;
  color: rgb(123, 73, 25);
  position: absolute;
  animation-name: slideImage;
  animation-timing-function: linear;
  animation-duration: 1s;
  top: 68%;
  left: 18%;
  width: 45%;
}

.onPic h1 {
  margin-left: 1rem;
  font-weight: 600;
  font-family: "Playfair Display:italic", serif;
}

.reviews {
  width: 100%;
  height: 30vh;
  margin-top: -6rem;
}

.review {
  display: inline-block;
  padding: 3rem;
  height: 100%;
}

.review iframe {
  height: 100%;
  width: 400px;
}

.Newsletter {
  width: 30%;
  margin-right: 5rem;
  background-color: blanchedalmond;
  border-radius: 15px;
  float: right;
  margin-top: 35rem;
}

@media (max-width: 1360px) {
  .mainPicture {
    display: none;
  }
  .onPic {
    display: none;
  }

  .mainText {
    position: relative;
    width: 90%;
    max-width: 100%;
    margin: 0;
    margin-top: 8rem;
    margin-left: 1em;
    height: 27rem;
  }

  .Newsletter {
    float: none;
    margin: 0;
    margin-top: 2rem;
    width: 90%;
    margin:1em;
  }
  .review {
    display: block;
    margin: 0;
    width: 100%;
    padding: 0;
    height: 19em;
  }

  .buffor {
    margin-top: 8rem;
  }

  .review iframe {
    width: 90%;
    margin: 1em;
  }
}
