.bottomBar{
    width:100%;
    background-color: #e3038c;
    height: 20rem;
    margin-top: -10rem;
}

.logo{
    height: auto;
    width: 60%;
}