.topBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 25px;
  background-color: rgba(242, 224, 185, 0.838);
  display: flex;
  flex-direction: row;
  box-sizing: content-box;
  z-index: 2137000;
  backdrop-filter: blur(7px);
}


.topBar > * {
  align-items: center;
  display: flex;
}


.logoContainer {
  height: 6rem;
}
.logoContainer img {
  height: 9rem;
  margin-bottom: -47px;
}



.burgerContainer {
  display: none;
}



.burgerContainer {
  display: none;
}

@media (max-width: 1360px) {
  .topBarActive {
    position: fixed;
    background-color: rgb(242, 224, 185);
    z-index: 2137000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;

  }
  .topBar{
    margin-left: 0;
    padding: 0;
  }

  .logoContainer {
    flex: 1;
    margin-right: 0;
    margin-left: 0;
  }
  .logoContainer img {
    height: 4rem;
    margin-bottom: 0;
    margin-right: 0;
  }

  .logoContainerActive {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .logoContainerActive img {
    display: flex;
    justify-content: center;
    height: 6rem;
    margin-bottom: 0;
  }
  .burgerContainer {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  .burgerContainerActive{
    text-align: center;
    display: block;
    margin:0;
  }

  .burgerContainerActive li{
    display: block;
    text-align: center;
    margin:0;
    padding:0;
  }

  .menuContainer {
    display: none;
  }

  .menuContainerActive {
    flex: 1;
    align-items: flex-start;
    overflow-y: scroll;
    display: block;
  }

  .basketContainer{
    margin-right: 1rem;
  }
  .basketContainerActive{
    display: block;
    text-align: center;
    margin:0;
    margin-top: 1rem;
  }
}
.basketContainerActive li{
  display: block;
  text-align: center;
  margin:0;
  padding:0;
}
