.basketBox{
    width: 40%;
    background-color: rgba(240, 214, 158, 0.993);
    height: 82vh;
    right: 0%;
    margin-top: 6rem;
    position: absolute;
    border-radius: 0 0 0 15px;
    position: fixed;
    top:0%;
    z-index: 2000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    display: block;
}

.basketBox h1{
    text-align: center;
    margin-top: 2%;
    text-decoration: underline;
    text-underline-offset: 8px;
}

.order{
    border: 2px ;
    border-style: none solid solid solid;
    border-radius: 0 0 15px 15px;
    height: 60vh;
    margin-right: 1rem;
    margin-left: 2rem;
    z-index: 2000;
    background-color: white;
    display: block;
}

.button{
  margin: 0;
  position: absolute;
  top: 93%;
  left: 52%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  
}

.totalPrice{
    align-self: center;
    margin-left: 20%;
    margin-top: -3%;
}

.bin:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.068);
    border-radius: 5px;
}
.bin{
    text-align: center;
    padding: 3px;
    font-size: large;
}

.cross{

    float: left;
    display: block;
}
.yourOrder{
    padding-right: 2rem;
    text-align: center;
    font-size: large;
    text-decoration: underline;
}
.cross:hover{
    cursor: pointer;
}


.backdrop{
   height: 100vh;
}
@media (max-width: 1350px) {
    .basketBox{
        width: 100%;
        height: 100vh;
    }
    .order{
        height: 40%;
        width: 99%;
        margin-left:1px;
        margin-right:1px;
        margin-top: 0;
        margin-bottom: 1rem;
    }
    .button{
        top:65%;
        margin: 0;
    }
    .totalPrice{
        margin: 0;
        margin-top: 2rem;
        text-align: center;

    }
    .backdrop{
        overflow:hidden
    }
}