.contactBox {
  width: 90%;
  max-width: 80%;
  height: 40rem;

  margin: 5rem auto;
  margin-top: 11rem;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  background: #9f7b61;

  display: grid;
}

.content {
  grid-column: 1;

  color: white;
}
.content h1 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 4rem;
}
.content p {
  margin-left: 5rem;
  font-size: x-large;
  word-wrap: break-word;
  max-width: 65%;
}

.col2 {
  grid-column: 2;
  margin-right: 2rem ;
}
.info {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.telLink {
  text-decoration: none;
  color: white;
}


@media (max-width: 1058px) {
  .contactBox{
    display: block;
    height: 100%;
    text-align: center;
  }

  .content p{
    max-width: 100%;
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }

  .col2{
    display: block;
    width: 100%;
    margin:0 auto;
  }
  .info{
    display:block;
    text-align: center;
  }
  .map{
    display: flex;
    justify-content: center;
  }


}