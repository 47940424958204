.cartButton{
    background-color: #1a92ca; /* Green */
    border: none;
    color: white;
    padding: 20px 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px ;
    cursor: pointer;
    font-family: "Playfair Display", serif;
}

.cartButton:hover{
   background-color: #1474a0;
}