.productsBox{
    width: 100%;
    margin: 3rem;
    margin-top: 2rem;
    margin-left: 0vh;
    justify-content: center;
    align-self: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-flow: row wrap;
}

.options{
    margin-top: 9rem;
    width: 30rem;
    margin-left: 50%;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.options h2{
    height: fit-content;
}

.search{
    margin-left: 20%;
    width: 80%;
    display: inline-block;
}

.productsInfo{
    display: inline-block;
}

.noProducts{
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top: 9rem;
}


@media (max-width: 1360px) {
    .productsBox{
        width: 90%;
    }

    .options{
        width: 100%;
        margin-left: 0;
    }
    .search{
        width: 80%;
    }
    .search h2{
        margin-left: -4rem;
    }
    .productsInfo{
        display: inline-block;
        margin-left: -3rem;
    }
    .noProducts{
        display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin-top: 9rem;
    }
}