.Newsletter{
    background: blanchedalmond;
    border-radius: 15px;
    padding: 10;
    display: inline-block;
}

.Newsletter h2{
    text-align: center;
    margin-bottom: 0;
}

.Newsletter input{
    width: 100%;
  padding: 12px 20px;
  margin-left: 1rem;
  margin-top: 0;
  box-sizing: border-box;
}