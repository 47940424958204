.tile{
    height: 550px;
    max-height: 550px;
    width: 300px;
    background-color: rgba(240, 185, 102, 0.87);
    border-radius: 15px;
    margin-left: 3rem;
    display: inline-block;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.imgDiv{
    width: 300px;
    height: 300px;
    margin: 0;
}

.imgDiv img{
    width: 300px;
    height: 300px;
    border-radius: 15px 15px 0 0;
    margin:0;
}

.infoPart{
    text-align: center;
    font-size: small;
    width: 100%;
    height: 120px;
    justify-content: center;
}

.infoPart h3{
    margin: 0;
    margin-top: 0.5rem;
    padding: 0;

    margin-left: 5px;
    margin-right: 5px;
}

.amountRow{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    font-family: "Playfair Display", serif;
}

.inputBox{
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: center;
    width: 20%;
}

.addButton{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Playfair Display", serif;
}


.price{
    background-color:#0481c4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    margin-left: 2rem;
    padding-bottom: 10px;
    border-radius: 15px;
    color:rgba(255, 255, 255, 0.87)
}
.productName h3{
    font-size: large;
    padding-bottom: 10px;
}

.price h3{
    font-size: large;
    font-weight: 400;
}
@media (max-width: 1360px) {
    .tile{
        margin:0;
        margin-top: 1rem;
        margin-left: 5%;
        width: 300px;
        height: 500px;
        overflow: hidden;
    }

    .imgDiv{
        width: 300px;
        height: 250px;
        margin: 0;
        overflow: hidden;
    }
    .productName h3{
        font-size: medium;
    }
    
    .imgDiv img{
        width: 300px;
        height: 300px;
        border-radius: 15px 15px 0 0;
        margin:0;
        overflow: hidden;
    }
    .amountRow{
        margin-top: 1.5rem;
    }
}